import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import {Row, Col, Image, Input, Spin, Tooltip} from 'antd';
import {useSelector, useDispatch} from "react-redux";
import {sleep} from "../../utils/utilfunc"
import axios from "axios";
import Swal from 'sweetalert2';
import MyAudioRecorder from "../../utils/MyAudioRecorder";
import {USER_SERVER} from '../../../config'
import {removeCookie} from "../../../cookie/cookie";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import TankImage from "../../../assets/images/tank_wide.png";

const Chat = forwardRef((props, ref) => {
    const { UserInfo, RecStatus, setRecStatus, WaitAnswer, setWaitAnswer, Img, chat_height } = props;

    const vrecorder = useRef(null);
    const audioRef = useRef(new Audio());

    const [HistoryList, setHistoryList] = useState([]);
    const [Question, setQuestion] = useState('');
    const [Mp3Url, setMp3Url] = useState(null);
    const [TooltipOpen, setTooltipOpen] = useState(false);
    const [WhilePlay, setWhilePlay] = useState(false);
    const isAuth = UserInfo?.isAuth;
    const [TTS, setTTS] = useState(true);

    console.log('Chat=RecStatus=', RecStatus, chat_height);

    const updateRecStatus = (newStatus) => {
        console.log('updateRecStatus=', newStatus);
        setRecStatus(newStatus);
        console.log('---------------');
    };

    useEffect(() => {
        get_chat_history();
    }, [UserInfo]);

    useEffect(() => {
        const handleEnded = () => {
            setWhilePlay(false);
        };

        // url이 null이 아닐 때만 재생
        if (Mp3Url) {
            setWhilePlay(true);
            audioRef.current.src = Mp3Url;
            audioRef.current.play().catch(error => console.log("재생 중 오류 발생:", error));
            audioRef.current.addEventListener('ended', handleEnded);
        }

        // Clean up the event listener when the component unmounts or Mp3Url changes
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleEnded);
            }
        };
    }, [Mp3Url]); // url 상태가 변경될 때마다 이 효과를 실행


    async function get_chat_history() {
        if (!UserInfo?.token)
            return;

        const res = await axios.post(`${USER_SERVER}/get_chat_history`, {
            user_id: UserInfo.user_id,
            token: UserInfo.token
        });
        console.log('get_chat_history--------->', res);
        if (res.data?.success) {
            setHistoryList(res.data.data);
        } else {
            show_error(res);
        }
    }

    // error 나면 에러 정보를 디스플레이
    function show_error(res) {
        const tdata = res.data.data;
        if (typeof tdata === 'object' && 'title' in tdata) {
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
            });
            if (tdata.type == 'tokenExpired') {
                removeCookie('w_auth');
                props.history.push("/login");
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: res.data.data,
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    }


    async function send_question_with_image(question, img) {
        console.log("send_question_with_image", img);
        setWaitAnswer(true);
        const formData = new FormData();
        formData.append('image', img);
        formData.append('question', question.trim());
        formData.append('user_id', UserInfo.user_id);
        formData.append('token', UserInfo.token);
        formData.append('tts', TTS);
        formData.append('status', JSON.stringify({
            hw_state: ''
        }));

        const res = await axios.post(`${USER_SERVER}/get_answer`, formData, {headers: {"Content-Type": "multipart/form-data"}});
        //URL.revokeObjectURL(ImgUrl);
        //setImgUrl("")
        //setImg(null);
        setWaitAnswer(false);
        console.log('get_answer--------->', res);
        if (res.data?.success) {
            await process_answer(res.data.data);
        } else {
            show_error(res);
        }
    }

    async function send_question(question) {
        setWaitAnswer(true);
        const currentDate = new Date();
        const dataToCommit = {
            token: UserInfo.token,
            user_id: UserInfo.user_id,
            question: question.trim(),
            tts: TTS,
            status: {hw_state: ''},
        }

        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('get_answer--------->', res);
        if (res.data?.success) {
            await process_answer(res.data.data);
        } else {
            show_error(res);
        }
    }

    async function process_answer(data) {
        const question = data.question;
        const answer = data.answer;
        const control_object = data.control;
        const user_msg = {speaker: 'h', message: question, image: data.image};
        const assistant_msg = {speaker: 'c', message: answer};
        const role = data?.role;
        setHistoryList([...HistoryList, user_msg, assistant_msg]);

        if (data?.function) {
            const tstr = JSON.stringify(data.function);
            const newstr = tstr.replace(/["\\"]/g, '');
            if (newstr !== '{}') {                 // empty 이면 추가 안함
                assistant_msg.gptfunc = newstr;
            }
        }

        if (data?.tts_url) {
            setMp3Url(data.tts_url);
        }
    }
   

    useEffect(() => {
        if (isAuth && document) {
            const divEl = document.getElementById("hide-scroll");
            divEl.scrollTo({behavior: "smooth", top: divEl.scrollHeight});
        }
    }, [HistoryList]);

    const render_histlist = HistoryList && HistoryList.map(function (hist, index) {
        const prompt = hist.speaker === 'h' ? '用户:  ' : '人工智能:  ';
        const func_msg = (hist?.gptfunc) ? '  (' + hist.gptfunc + ')' : '';
        const hist_msg = (hist.message==='custom2') ? '地图描述' : hist.message;

        return (
            <div key={index}>
                {
                    hist.image !== "" &&
                    <Row>
                        <img src={hist.image} alt={hist.image} style={{maxWidth: "500px", maxHeight: "300px"}}/>
                    </Row>
                }
                <Row>
                    <Col>
                        {prompt}
                        {/* {hist_msg} */}
                        <MarkdownRenderer text={hist_msg}/>
                        {func_msg}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {hist.speaker === 'c' && <br/>}
                    </Col>
                </Row>
                {hist.speaker === "c" && index !== HistoryList.length - 1 && <hr/>}
            </div>
        );
    });


    async function sendDataToBackend(data) {
        try {
            setWaitAnswer(true);
            const formData = new FormData();
            if (Img) {
                formData.append("image", Img);
            }
            formData.append('audio', data);
            formData.append('user_id', UserInfo.user_id);
            formData.append('token', UserInfo.token);
            formData.append('status', JSON.stringify({
                //parking_remaining_sec: get_parking_remaining_sec()
                hw_state: ''
            }));

            const res = await axios.post(`${USER_SERVER}/get_answer_speech`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setWaitAnswer(false);
            console.log('get_answer_speech --------->', res);
            if (res.data?.success) {
                await process_answer(res.data.data);
            } else {
                show_error(res);
            }

            console.log('Data sent successfully', res);
        } catch (error) {
            console.error('Error sending data to backend', error);
        }
    }

    function MarkdownRenderer({text}) {
        return (
            <div>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {text}
                </ReactMarkdown>
            </div>
        );
    };

    function onClickStopPlaying() {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setWhilePlay(false);
        }
    };


    //////////////////////////////////////////////////////////////////////////////
    async function onClickSend() {
        console.log('onClickSend', Img);
        if (Img) {
            await send_question_with_image(Question, Img);
        } else {
            await send_question(Question);
        }
    }


    async function onClickRecStart() {
        console.log('onClickRecStart=', RecStatus);
        if (RecStatus !== 'ready') {
            return;
        }

        updateRecStatus('prepare_rec');
        try {
            vrecorder.current = new MyAudioRecorder();

            await vrecorder.current.openMic();
            await vrecorder.current.start_recording();
            updateRecStatus('rec');
        } catch (err) {
            console.log(err.message);	// device가 없어서 error가 난 것으로 추정
            updateRecStatus('ready');
        }
    }


    // stop recording
    async function onClickRecStop() {
        console.log('onClickRecStop=', RecStatus);
        if (RecStatus !== 'rec') {
            return;
        }
        updateRecStatus('prepare_stop');
        await vrecorder.current.stop_recording();

        for (let k = 0; k < 30; k++) {
            const vsign_url = vrecorder.current.get_blob_url();
            if (vsign_url === 'too_short') {
                updateRecStatus('ready');
                console.log('too short ---------------------------------', k);
                return;
            } else if (vsign_url) {
                console.log('check recorded data --------------------', k, vsign_url);
                const data = vrecorder.current.get_recorded_blob();
                await sendDataToBackend(data);
                updateRecStatus('ready');
                console.log('======', vsign_url, k)
                return;
            }
            await sleep(100);
        }
        alert('Error');
    }


    // async function onClickUploadImage() {
    //     console.log('onClickUploadImage');
    // }


    async function onClickReset() {
        console.log('onClickReset');
        setWaitAnswer(true);
        const res = await axios.post(`${USER_SERVER}/reset_history`, {
            token: UserInfo.token,
            user_id: UserInfo.user_id
        });
        console.log('reset_history--------->', res);
        if (res.data?.success) {
            await get_chat_history();
        } else {
            alert('Error:' + res.data.data);
        }
        setWaitAnswer(false);
    }

    async function onClickCustom1() {
        console.log('onClickCustom1');
        await send_question('无人机现状');
    }

    async function onClickCustom2() {
        console.log('onClickCustom2');
        await send_question('custom2');
    }
    
    useImperativeHandle(ref, () => ({
        onClickSend,
        //onClickUploadImage,
        onClickRecStart,
        onClickRecStop,
        onClickReset,
        onClickCustom1,
        onClickCustom2
    }));


    function onClickTTS() {
        setTTS(!TTS);
    }

    //////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            {!isAuth && 
            <Row>
                <Col>
                    <img 
                        src={TankImage} 
                        alt="Tank"
                        style={{
                            width: '100%',
                            height: `${chat_height}px`,
                            display: 'block',
                            objectFit: 'cover'
                    }}
                    />
                </Col>
            </Row>
            }
            {isAuth && 
            <div>
                <Row
                    style={{
                        height: `${chat_height - 50}px`,
                        overflowY: "scroll"
                    }}
                    id={"hide-scroll"}
                >
                    <Col style={{margin: '10px'}}>
                        {render_histlist}
                    </Col>
                </Row>

                <Row justify="center" align='middle'>
                    <Col span={2}>
                        <div onClick={onClickTTS} style={{cursor: 'pointer', color: TTS ? 'green' : 'darkred', fontSize: '10px', textAlign: 'center', display: 'block'}}><strong>TTS</strong></div>
                    </Col>

                    <Col span={20}>
                        输入:
                        <Input
                            disabled={WaitAnswer}
                            value={Question}
                        onChange={(e) => {
                            setQuestion(e.currentTarget.value);
                        }}
                        onPressEnter={onClickSend}
                        style={{width: '85%', margin: 10}}
                        />
                    </Col>
                </Row>
            </div>
            }
        </div>
    )
})

export default Chat;
